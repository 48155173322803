<template>
	<b-modal id="live-class-attendance-modal" @change="$emit('updateModalState', false)" :visible="visible" hide-header
		centered content-class="live-class-attendance-modal" size="xl" body-class="modal-body" hide-footer
		modal-class="custom-modal" no-close-on-backdrop>
		<button @click="$emit('updateModalState', false)" class="close-button" role="button">
			<img src="@/assets/images/cancel-icon.svg" />
		</button>

		<div class="body-container">
			<h1 class="add-content-title">{{ contentData.title }}</h1>
			<div class="mb-3 text-black d-flex flex-column">
				<div class="d-flex gap-5 align-items-center mb-5">
					<div class="flex-grow-1">
						<span class="text-bold">Live Class Attendance</span>
						<div class="text-muted" v-if="contentData.extraProperties">
							<strong>Start Time:</strong>
							{{ timeParser(contentData.availableFrom) }},
							{{ dateParser(contentData.availableFrom) }} |
							<strong>Duration:</strong>
							{{ calculatedDuration() }} Minutes
							
						</div>
					</div>
					<div class="searchbar flex-grow-1">
						<div class="form-check form-switch">
							<input class="form-check-input" type="checkbox" role="switch" id="isIncludeInActiveStudents"
								name="isIncludeInActiveStudents" v-model="isIncludeInActiveStudents" />
							<label class="form-check-label" for="isIncludeInActiveStudents">Include Inactive
								Students</label>
						</div>

					</div>
					<div class="searchbar flex-grow-1">
						<input type="search" autocomplete="off" id="variant-search" placeholder="Search students"
							class="form-input" v-model="searchText" />
						<a role="button"><img class="search-icon" src="@/assets/images/search-icon.svg" /></a>
					</div>
				</div>
			</div>

			<div class="attendance-status-container">
				<div class="attendance-present col-6 pe-3">
					<h5 class="mb-3">
						Face-to-Face Students ({{ searchFiltered(presentStudents).length }})
					</h5>
					<div class="attendance-list-container" v-if="searchFiltered(presentStudents).length">
						<AttendanceListItem v-for="item in searchFiltered(presentStudents)" :key="item.user.id"
							:type="AttendanceStatus.PRESENT" :studentData="{ type: 'Student', ...item.user }"
							:contentData="contentData" @refreshAttendance="refresh" :studentAttendance="item.attendance" />
					</div>
					<div v-else class="no-content">
						<b-spinner v-if="loading"></b-spinner>
						<span v-else>No present students
							{{ searchText? "that match your search": "" }}</span>
					</div>
				</div>
				<div class="attendance-online col-6 ps-3">
					<h5 class="mb-3">
						Online Students ({{ searchFiltered(onlineStudents).length }})
					</h5>
					<div class="attendance-list-container" v-if="searchFiltered(onlineStudents).length">
						<AttendanceListItem v-for="item in searchFiltered(onlineStudents)" :key="item.user.id"
							:type="AttendanceStatus.ONLINE" :studentData="{ type: 'Student', ...item.user }"
							:contentData="contentData" @refreshAttendance="refresh" :studentAttendance="item.attendance" />
					</div>
					<div v-else class="no-content">
						<b-spinner v-if="loading"></b-spinner>
						<span v-else>No online students
							{{ searchText? "that match your search": "" }}
						</span>
					</div>
				</div>
				<div class="attendance-absent col-6 pe-3">
					<h5 class="mb-3">
						Absent Student ({{ searchFiltered(absentStudents).length }})
					</h5>
					<div class="attendance-list-container" v-if="searchFiltered(absentStudents).length">
						<AttendanceListItem v-for="item in searchFiltered(absentStudents)" :key="item.id"
							:type="AttendanceStatus.ABSENT" :studentData="{ type: 'Student', ...item }"
							:contentData="contentData" @refreshAttendance="refresh" />
					</div>
					<div v-else class="no-content">
						<b-spinner v-if="loading"></b-spinner>
						<span v-else>No absent students
							{{ searchText? "that match your search": "" }}</span>
					</div>
				</div>
				<div class="attendance-unpaid col-6 ps-3">
					<h5 class="mb-3">
						Unpaid Students ({{ searchFiltered(unpaidStudents).length }})
					</h5>
					<div class="attendance-list-container" v-if="searchFiltered(unpaidStudents).length">
						<AttendanceListItem v-for="item in searchFiltered(unpaidStudents)" :key="item.id"
							:type="AttendanceStatus.UNPAID" :studentData="{ type: 'Student', ...item }"
							:contentData="contentData" @refreshAttendance="refresh" />
					</div>
					<div v-else class="no-content">
						<b-spinner v-if="loading"></b-spinner>
						<span v-else>No unpaid students
							{{ searchText? "that match your search": "" }}</span>
					</div>
				</div>
			</div>
			<div class="step-btn-container">
				<button @click="closeModal" role="button" class="skip-step-btn">
					cancel
				</button>
				<a @click="prev" v-if="showPrev" role="button" class="step-btn prev"><i
						class="fas fa-long-arrow-alt-left"></i> prev
				</a>
				<!-- <button
					@click="next"
					role="button"
					class="step-btn"
					:disabled="disableNext || loading"
				>
					<b-spinner v-if="loading" small></b-spinner>
					{{ stepButtonContent }} <i class="fas fa-long-arrow-alt-right"></i>
					<img src="@/assets/images/arrow-right.svg" />
				</button> -->
				<button class="step-btn" @click="refresh">
					<b-spinner v-if="loading" small class="me-2"></b-spinner>
					<i v-else class="far fa-redo me-2"></i> Refresh
				</button>
				<button
		       					    @click="exportAttendanceData"
									:disabled="exportLoading"
		       					    :class="['step-btn','export-excel-btn']"
		       					>
		       					  Export As CSV
		       					</button>
			</div>
		</div>
	</b-modal>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
	AttendanceStatus,
	AttendanceType,
} from "../../../constants/attendance";
import AttendanceListItem from "./AttendanceListItem.vue";
import formatter from "../../../helpers/FormatHelpers";
export default {
	name: "LiveClassAttendanceModal",
	data() {
		return {
			absentStudents: [],
			presentStudents: [],
			onlineStudents: [],
			unpaidStudents: [],
			reportAbsentStudents: [],
			searchText: "",
			AttendanceStatus,
			AttendanceType,
			isIncludeInActiveStudents: false,
			exportLoading: false
		};
	},
	props: { showModal: Boolean, productVariantId: String, contentData: Object },
	computed: {
		...mapState("user", ["loading"]),
		...mapState("user", ["usersByProductVariant"]),
		visible() {
			return this.showModal;
		},
		showPrev() { },
		stepButtonContent() {
			return "Save";
		},
		disableNext() { }
	},
	components: {
		AttendanceListItem,
	},
	methods: {
		...mapActions("user", ["fetchUsersByProductVariant"]),
		...mapActions("content", [
			"fetchLiveClassAttendance",
			"markLiveClassAttendance",
			"exportAttendanceReport"
		]),
		closeModal() {
			this.$emit("updateModalState", false);
			Object.assign(this.$data, this.$options.data.call(this));
		},
		prev() { },
		next() { },
		dateParser(date) {
			return formatter.toShortDateString(date);
		},
		exportAttendanceData() {
			this.exportLoading = true;
			this.exportAttendanceReport({
				contentID:this.contentData.id, 
				productVariantID:this.productVariantId
			}).then((result) => {

				if (result && result.url) {
					const link = document.createElement('a');
					link.href = result.url;
					link.setAttribute('download', result.name ? result.name : `users-enrollments-export.csv`);
					document.body.appendChild(link);
					this.exportLoading = false;
					link.click();
					link.parentNode.removeChild(link);
					// clean up Url
					window.URL.revokeObjectURL(result);
				}
			}).catch((err) => {
				console.log({ err });

				this.exportLoading = false;
			});
		},
		timeParser(date) {
			return formatter.getTimeIn12Hours(date);
		},
		calculatedDuration() {
			var startTime = new Date(this.contentData.availableFrom).getTime();
			var endTime = new Date(this.contentData.availableTo).getTime();
			var diff = endTime - startTime;
			return diff / (1000 * 60);
		},
		async refresh() {
			await this.fetchLiveClassAttendance(this.contentData.id).then((res) => {
				this.reportAbsentStudents = res.filter(
					(attendance) =>
						attendance.status == "absent"
				);
				this.onlineStudents = res.reduce((acc, attendance) => {
					if (
						attendance.attendedFrom == AttendanceType.ONLINE &&
						attendance.status == AttendanceStatus.PRESENT
					) {
						const userId = attendance.user.id;
						const existingStudent = acc.find(item => item.user.id == userId);

						if (existingStudent) {
							existingStudent.attendance.push(attendance);
						} else {
							acc.push({
								user: attendance.user,
								attendance: [attendance],
							});
						}
					}
						return acc;
					}, []);
				this.presentStudents = 
					res.reduce((acc, attendance) => {
						console.log({attendance});
						if (
							attendance.attendedFrom == AttendanceType.PHYSICAL &&
								attendance.status == AttendanceStatus.PRESENT
						) {
							const userId = attendance.user.id;
							const existingStudent = acc.find(item => item.user.id === userId);

							if (existingStudent) {
								existingStudent.attendance.push(attendance);
							} else {
								acc.push({
									user: attendance.user,
									attendance: [attendance],
								});
							}
						}
						return acc;
					}, []);

			});
			this.fetchUsersByProductVariant(this.productVariantId).then((res) => {

				this.absentStudents = this.usersByProductVariant[
					this.productVariantId
				].filter((user) => {
					var isPresent = this.presentStudents.find((u) => u.user.id == user.id);
					var isOnline = this.onlineStudents.find((u) => u.user.id == user.id);

					if (!isPresent && !isOnline) {
						var absentFound = this.reportAbsentStudents.find(x => x.userID == user.id);
						user.Reason = absentFound ? absentFound.reason : "";
						return user && user.enrollmentStatus && user.enrollmentStatus?.toLowerCase() == 'allowed';
					}
				});




			});
		},
		searchFiltered(list) {
			return list.filter((item) => {
				const currentItem = item.user ? item.user : item;

				return this.isUserEnrolled(currentItem.id) &&
					(this.isIncludeInActiveStudents == true || (currentItem.status && currentItem.status.toLowerCase() == "active"))
					&& (
						(currentItem.firstName || "").toLocaleLowerCase().indexOf(this.searchText.toLocaleLowerCase()) > -1 ||
						(currentItem.lastName || "").toLocaleLowerCase().indexOf(this.searchText.toLocaleLowerCase()) > -1 ||
						(currentItem.username || "").toLocaleLowerCase().indexOf(this.searchText.toLocaleLowerCase()) > -1 ||
						(currentItem.email || "").toLocaleLowerCase().indexOf(this.searchText.toLocaleLowerCase()) > -1
					);
			});
		},
		isUserEnrolled(userId) {
			if (!userId) return false;
			const userIdStr = String(userId);
			const users = this.usersByProductVariant ? this.usersByProductVariant[this.productVariantId] : [];
			return users.some(user => String(user.id) === userIdStr);
		},
	},
	watch: {
		visible: async function () {
			if (this.visible) {
				await this.fetchLiveClassAttendance(this.contentData.id).then((res) => {
					this.reportAbsentStudents = res.filter(
						(attendance) =>
							attendance.status == "absent"
					);
					this.onlineStudents = res.reduce((acc, attendance) => {
						if (
							attendance.attendedFrom === AttendanceType.ONLINE &&
							attendance.status === AttendanceStatus.PRESENT
						) {
							const userId = attendance.user.id;
							const existingStudent = acc.find(item => item.user.id === userId);

							if (existingStudent) {
								existingStudent.attendance.push(attendance);
							} else {
								acc.push({
									user: attendance.user,
									attendance: [attendance],
								});
							}
						}
							return acc;
						}, []);
					this.presentStudents = 
						res.reduce((acc, attendance) => {
							if (
								attendance.attendedFrom == AttendanceType.PHYSICAL &&
									attendance.status == AttendanceStatus.PRESENT
							) {
								const userId = attendance.user.id;
								const existingStudent = acc.find(item => item.user.id === userId);

								if (existingStudent) {
									existingStudent.attendance.push(attendance);
								} else {
									acc.push({
										user: attendance.user,
										attendance: [attendance],
									});
								}
							}
							return acc;
						}, []);
				});
				this.fetchUsersByProductVariant(this.productVariantId).then((res) => {
					this.absentStudents = this.usersByProductVariant[
						this.productVariantId
					].filter((user) => {
						var isPresent = this.presentStudents.find((u) => u.user.id == user.id);
						var isOnline = this.onlineStudents.find((u) => u.user.id == user.id);

						if (!isPresent && !isOnline) {
							var absentFound = this.reportAbsentStudents.find(x => x.userID == user.id);
							user.Reason = absentFound ? absentFound.reason : "";
							return user && user.enrollmentStatus && user.enrollmentStatus?.toLowerCase() == 'allowed';
						}
					});

					this.unpaidStudents = this.usersByProductVariant[
						this.productVariantId
					].filter((user) => {
						var isPresent = this.presentStudents.find((u) => u.user.id == user.id);
						var isOnline = this.onlineStudents.find((u) => u.user.id == user.id);
						if (!isPresent && !isOnline) {

							return user && (!user.enrollmentStatus || user.enrollmentStatus?.toLowerCase() != 'allowed');
						}
					});
				});
			}
		},
	},
	created() { },
};
</script>

<style>
.live-class-attendance-modal .form-input {
	border: 0;
	border-bottom: 1.5px solid #b2b2b2;
	font-size: 1rem;
	outline: none;
	padding: 0 0 0.875rem;
	/* max-width: 21.625rem; */
	width: 100%;
	border-radius: 0;
	box-shadow: none !important;
	position: relative;
	height: 40px;
}

.live-class-attendance-modal .body-container {
	padding: 0;
	display: flex;
	flex-direction: column;
	width: 100%;
	position: relative;
}

.live-class-attendance-modal .add-btn {
	position: absolute;
	top: 2rem;
	right: 4.5rem;
	z-index: 99 !important;
}

.attendance-status-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-bottom: 1rem;
}

.attendance-present h5,
.attendance-online h5,
.attendance-absent h5,
.attendance-unpaid h5 {
	padding-bottom: 0.5rem;
	border-bottom: 1px solid #dddddd;
}

.attendance-absent,
.attendance-unpaid {
	margin-top: 1rem;
}

.attendance-present .attendance-list-container,
.attendance-online .attendance-list-container,
.attendance-absent .attendance-list-container,
.attendance-unpaid .attendance-list-container {
	height: 25vh !important;
	overflow-y: auto !important;
}

/* {
    min-height: 25vh;
} */

.live-class-attendance-modal .close-button {
	z-index: 99 !important;
}

.live-class-attendance-modal .no-content {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 25vh !important;
}
.export-excel-btn {
	background: var(--primary-color);
	color: white;
  }

  .export-excel-btn:hover {
	background: white !important;
	color: black !important;
	border: 1px solid var(--primary-color) !important;
  }
</style>
