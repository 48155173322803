<template>
	<div
		class="product-details-card-mobile"
		v-if="!loading && productVariantData != null"
	>
		<div class="product-card-content">
			<div class="content-container">
				<h3
					v-if="productVariantData.status == ProductVariantStatus.COMING_SOON"
				>
					{{ productVariantData.status }}
				</h3>
        <div v-else-if="!contactUsFormLink">

          <h3 v-if="plansLoaded" class="product-price text-bold">
            <span v-if="priceBeforeDiscount != priceAfterDiscount" class="text-danger pre-discounted-price">
              <del>
                {{ parseCurrency(lowestVariantPrice.price, selectedPlan.currency) }}
                <p
                    class="m-0 text-muted superscript"
                    v-if="
                    filteredPricingPlans &&
                    filteredPricingPlans.length > 0 &&
                    filteredPricingPlans[0].type ==
                      PricingPlanType.MULTIPLE_PAYMENT_CYCLES &&
                    hasOnlyOnePlan">
                    /month
                </p>
              </del>
            </span>
            {{
              lowestVariantPrice.discountedPrice > 0
                ? parseCurrency(lowestVariantPrice.discountedPrice, selectedPlan.currency, 2)
                : lowestVariantPrice.discountedPrice == 0
                ? "Free"
                : "This course is not available in you country"
            }}
            <p
              class="m-0 text-muted superscript"
              v-if="
                filteredPricingPlans &&
                filteredPricingPlans.length > 0 &&
                filteredPricingPlans[0].type ==
                  PricingPlanType.MULTIPLE_PAYMENT_CYCLES &&
                hasOnlyOnePlan
              "
            >
              /month
            </p>
            <sub class="discount-percentage-off" v-if="priceBeforeDiscount != priceAfterDiscount">({{discountPercentage}}% off)</sub>
          </h3>
        </div>
				<p v-if="productVariantDates" class="product-dates">
					<i class="fas fa-history icon-gray"></i>
					<span
						v-if="productVariantData.startDate && productVariantData.endDate"
					>
						{{ productVariantDates }}<br />
					</span>
				</p>
			</div>
			<div class="button-container">
        <a v-if="contactUsFormLink" :href="contactUsFormLink" target="_blank" class="success-btn">Contact Us</a>
				<button v-else
					@click="handleClick"
					role="button"
					class="success-btn"
					:disabled="lowestVariantPrice.discountedPrice == -1"
				>
					{{ btnText }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import router from "../../router";
import { mapActions, mapState } from "vuex";
import {
	toCurrencyString,
	toShortDateString,
} from "../../helpers/FormatHelpers";

import {
	PricingPlanType,
	ProductVariantPricingStatus,
} from "../../constants/pricing";

import { ProductVariantStatus } from "../../constants/productVariant";

export default {
	name: "ProductDetailsCardMobile",
	props: { productVariantData: Object },
	data() {
		return {
			plansLoaded: false,
			selectedPlan: null,
			isThumbnailError: false,
			PricingPlanType,
			ProductVariantPricingStatus,
			ProductVariantStatus,
		};
	},
	methods: {
		...mapActions("cart", ["addToCart"]),
		...mapActions("pricing", ["fetchPricingPlanByProductVariantId"]),
		handleClick() {
			if (!this.isInCart) {
				// console.log("buy now clicked");
				if (this.productVariantData.pricingStatus.toLowerCase() == "paid") {
					if (
						this.hasOnlyOnePlan &&
						this.filteredPricingPlans[0].type == PricingPlanType.ONE_TIME
					) {
						this.productVariantData.selectedPlan = this.selectedPlan;
						this.productVariantData.pricing.amount = this.lowestVariantPrice.discountedPrice;
						this.productVariantData.pricing.currency =
							this.selectedPlan.currency;
						this.addToCart(this.productVariantData);
					} else {
						this.$emit("togglePlansModal", true);
					}
				} else {
					this.addToCart(this.productVariantData);
					router.push("/shop/checkout");
				}
			} else {
				router.push("/shop/checkout");
			}
		},
		parseCurrency(amount, currency, decimal) {
			return toCurrencyString(amount, currency, decimal);
		},
	},
	computed: {
		...mapState("cart", ["cart"]),
		...mapState("productVariant", ["loading"]),
		...mapState("pricing", ["pricingPlanByProductVariantId"]),
		...mapState("appState", ["currentCountry", "systemParameters"]),
		isInCart() {
			var found = false;
			this.cart.items.map((item) => {
				if (item.id == this.productVariantData.id) found = true;
			});
			return found;
		},
		btnText() {
			if (this.isInCart) {
				return "View Cart";
			}
			if (this.lowestVariantPrice.discountedPrice == 0) {
				return "Enroll Now";
			}
			return "Buy Now";
		},
		productVariantDates() {
			var dateString = null;
			if (
				this.productVariantData.startDate &&
				!this.productVariantData.endDate
			) {
				dateString =
					"Starting from " +
					toShortDateString(this.productVariantData.startDate);
			} else if (
				!this.productVariantData.startDate &&
				this.productVariantData.endDate
			) {
				dateString =
					"Till " + toShortDateString(this.productVariantData.endDate);
			} else if (
				this.productVariantData.startDate &&
				this.productVariantData.endDate
			) {
				dateString =
					toShortDateString(this.productVariantData.startDate) +
					" - " +
					toShortDateString(this.productVariantData.endDate);
			}
			return dateString;
		},
		filteredPricingPlans() {
			if (
				this.productVariantData &&
				this.pricingPlanByProductVariantId[this.productVariantData.id]
			) {
				return this.pricingPlanByProductVariantId[
					this.productVariantData.id
				].filter(
					(plan) =>
						this.currentCountry &&
						plan.country &&
						plan.country.indexOf(this.currentCountry.toUpperCase()) != -1
				);
			} else {
				return [];
			}
		},
    lowestVariantPrice() {

      let retVal = {
        price: undefined,
        discountedPrice: undefined
      }

	  if(this.productVariantData && this.productVariantData.pricingStatus == ProductVariantPricingStatus.FREE) {
        retVal.price = 0;
        retVal.discountedPrice = 0;
        return retVal;
      }
	  
      if(!this.productVariantData || !this.filteredPricingPlans || this.filteredPricingPlans.length <= 0) {
        retVal.price = -1;
        retVal.discountedPrice = -1;
        return retVal;
      }

      retVal.price = Number.MAX_VALUE;

      this.filteredPricingPlans.forEach((plan) => {
        plan.details.forEach((detail) => {
          let planAmount = detail.amountDetail.find(x => x.title.toLowerCase() == "price").amount || detail.amount;
          let planHasDiscount = detail.amountDetail.find(x => x.title.toLowerCase() == "discount") ? true : false;
          let planDiscount = planHasDiscount ? Math.abs(detail.amountDetail.find(x => x.title.toLowerCase() == "discount").amount) : 0.00;

          let discountedAmount = planAmount - planDiscount;

          if (discountedAmount < (retVal.discountedPrice || retVal.price)) {
            retVal.price = planAmount;
            retVal.discountedPrice = discountedAmount;
          }
        });
      });
      return retVal;

    },
		hasOnlyOnePlan() {
			if (
				this.productVariantData &&
				this.filteredPricingPlans &&
				this.filteredPricingPlans.length > 0
			) {
				return this.filteredPricingPlans.length == 1;
			} else return false;
		},
    contactUsFormLink() {
      console.log({'contactUsLinkFromSP':this.systemParameters.ContactUsLinkForProductVariantEnrollment});

      return this.productVariantData.properties && this.productVariantData.properties.contact_us_link_for_enrollment ?
          this.productVariantData.properties.contact_us_link_for_enrollment :
          this.systemParameters && this.systemParameters.ContactUsLinkForProductVariantEnrollment ?
              this.systemParameters.ContactUsLinkForProductVariantEnrollment :
              undefined;
    },
    priceBeforeDiscount() {
      return this.lowestVariantPrice.price;
    },
    priceAfterDiscount() {
      return this.lowestVariantPrice.discountedPrice;
    },
    discountPercentage() {
      if(this.priceBeforeDiscount == this.priceAfterDiscount)
        return 0.00;

      return ((1 - (this.priceAfterDiscount/ this.priceBeforeDiscount)) * 100);
    }
	},
	created() {
		if (
			!this.productVariantData ||
			(this.productVariantData &&
				!this.pricingPlanByProductVariantId[this.productVariantData.id])
		) {
			this.fetchPricingPlanByProductVariantId(this.productVariantData.id).then(
				() => {
					this.plansLoaded = true;
					if (this.filteredPricingPlans && this.filteredPricingPlans.length)
						this.selectedPlan = this.filteredPricingPlans[0];
				}
			);
		} else {
			this.plansLoaded = true;
			if (this.filteredPricingPlans && this.filteredPricingPlans.length)
				this.selectedPlan = this.filteredPricingPlans[0];
		}
	},
	watch: {
		productVariantData: function () {
			if (
				!this.productVariantData ||
				(this.productVariantData &&
					!this.pricingPlanByProductVariantId[this.productVariantData.id])
			) {
				this.fetchPricingPlanByProductVariantId(
					this.productVariantData.id
				).then(() => {
					this.plansLoaded = true;
					if (
						this.pricingPlanByProductVariantId[this.productVariantData.id] &&
						this.pricingPlanByProductVariantId[this.productVariantData.id]
							.length > 0
					)
						this.selectedPlan = this.filteredPricingPlans[0];
				});
			} else {
				this.plansLoaded = true;
				if (
					this.pricingPlanByProductVariantId[this.productVariantData.id] &&
					this.pricingPlanByProductVariantId[this.productVariantData.id]
						.length > 0
				)
					this.selectedPlan = this.filteredPricingPlans[0];
			}
		},
	},
};
</script>

<style scoped>
/* @media screen and (max-width: 996px) {
	.product-details-card {
		top: 1rem !important;
		min-height: unset !important;
		border-radius: 0 !important;
		border: none !important;
	}

	.product-details-card-container {
		padding: 0 !important;
		margin: 0 -12px 0 !important;
	}

	.product-card-content {
		padding-bottom: 0 !important;
	}
}

@media screen and (max-width: 768px) {
	.product-card-content {
		font-size: 1.1rem !important;
		padding: 1rem !important;
	}

	.product-card-content p {
		font-size: 1rem !important;
		margin-bottom: 0 !important;
		padding: 1rem !important;
	}

	.add-promo {
		margin-top: 1rem !important;
	}
}

@media screen and (max-width: 600px) {
	.product-details-card {
		top: 0 !important;
	}
} */

.product-details-card-mobile {
	width: 100%;
	position: fixed;
	/* min-height: 500px; */
	background-color: var(--color-white);
	bottom: 0;
	/* border-radius: 0.625rem; */
	z-index: 10;
	/* border: 1px solid #bbbdbf; */
	box-shadow: 0 -3px 6px #99999945;
}

.product-card-content {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
	text-align: left;
}

.product-price {
	font-size: 1.25rem;
	font-weight: bold;
}

.product-price .pre-discounted-price {
  font-size: 1rem;
  font-weight: normal;
  display: block;
}

.discount-percentage-off {
  font-size: 0.75rem;
  font-weight: normal;
  display: block;
}

.product-dates {
	margin-bottom: 0;
}

.product-card-content p {
	font-size: 0.875rem;
}

.icon-gray {
	color: #a7a9ac;
	margin-right: 0.25rem;
	font-size: 0.875rem;
}

.button-container {
	display: flex;
	flex-direction: column;
	margin-left: 0.5rem;
	max-width: 60vw;
	min-width: 40%;
	row-gap: 0.5rem;
}

.button-container button, .button-container a {
	padding: 1.125rem 0;
	font-size: 1rem;
	font-weight: 500;
	line-height: 1.3;
	box-shadow: 0 3px 6px #d5d5d529;
}
</style>
