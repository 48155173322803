<template>
	<div>
		<ProfileBanner />
		<div class="container profile-detail-container">
			<div class="profile-picture-container">
				<input @change="getFile" type="file" hidden id="image-upload" />
				<img
					v-if="userPicturePath != null"
					@click="!isStudentProfileEditDisabled ? openDialog : null"
					:src="userPicturePath"
					alt="profilePicture"
					class="profile-picture"
				/>
				<div v-else class="profile-picture">
					<i class="far fa-user"></i>
				</div>
				<a
					v-if="!isStudentProfileEditDisabled"
					@click="profilePicturePath ? updateProfile() : openDialog()"
					role="button"
					class="profile-picture-edit"
				>
					<i
						v-if="!loading"
						:class="`fas ${
							profilePicturePath ? 'fa-check' : 'fa-pencil-alt'
						} edit-button-icon`"
					></i>
					<b-spinner small v-if="loading"></b-spinner>
				</a>
			</div>
			<div class="d-flex gap-3 flex-column">
				<a v-if="!isStudentProfileEditDisabled" role="button" class="border-btn edit-btn" @click="toggleModal"
					><i class="fas fa-pencil-alt edit-btn-icon"></i>Edit Profile</a
				>
				<a
					role="button"
					class="border-btn edit-btn"
					@click="togglePasswordModal"
					><i class="fas fa-lock-alt edit-btn-icon"></i>Change Password
				</a>
			</div>
			<h1 class="user-name-title">
				{{ userProfileData.firstName }} {{ userProfileData.lastName }}
			</h1>
			<h2 class="user-role">
				{{ userProfileData.role }}
			</h2>
			<h3 class="user-location">
				{{ userProfileData.city }} - {{ userProfileData.country }}
			</h3>
			<p class="user-email">
				<i class="far fa-envelope"></i> {{ userProfileData.email }}
			</p>
			<p v-if="userProfileData.address" class="user-address">
				<i class="fas fa-map-marker-alt"></i> {{ userProfileData.address }}
			</p>
			<img id="barcodeImg" hidden />

      <qrcode-vue :value="this.user.id" :size="200" level="H" render-as="svg" />
			<!-- <svg id="barcodeSvg"></svg>
			<button v-if="previewItem" @click="openPreview" class="border-btn">
				Zoom
			</button> -->
			<!-- <vue-easy-lightbox
				escDisabled
				moveDisabled
				:visible="showPreview"
				:imgs="previewItem"
				@hide="handleHide"
			></vue-easy-lightbox> -->
		</div>
		<EditProfileModal
			:showModal="showModal"
			@updateModalState="updateModalState"
			:profileData="userProfileData"
		/>
		<ChangePasswordModal
			:showModal="showChangePasswordModal"
			@updateModalState="updateModalPasswordState"
			:profileData="userProfileData"
		/>
	</div>
</template>

<script>
import InnerPageBanner from "../components/Layout/InnerPageBanner.vue";
import ProfileBanner from "../components/Layout/ProfileBanner.vue";
import EditProfileModal from "../components/Profile/EditProfileModal.vue";
import ChangePasswordModal from "../components/Profile/ChangePasswordModal.vue";
import { mapActions, mapState } from "vuex";
import { countryCodes } from "../constants/countryCodes";
import VueEasyLightbox from "vue-easy-lightbox";
import QrcodeVue from 'qrcode.vue'

export default {
	name: "Profile",
	components: {
		InnerPageBanner,
		ProfileBanner,
		EditProfileModal,
		VueEasyLightbox,
		ChangePasswordModal,
    QrcodeVue
	},
	data() {
		return {
			showModal: false,
			showChangePasswordModal: false,
			profilePicturePath: null,
			profilePictureFile: null,
			showPreview: false,
			previewItem: "",
		};
	},
	computed: {
		...mapState("user", ["user"]),
		...mapState("content", ["loading"]),

		...mapState("appState", ["systemParameters"]),

		isStudentProfileEditDisabled() {
			return this.systemParameters 
				&& this.systemParameters.DisableStudentProfileEdit 
				&& this.systemParameters.DisableStudentProfileEdit.toString().toLowerCase() === 'true';
		},
		
		userProfileData() {

			var country=countryCodes.find(
				(c) => c["alpha-3"] == this.user.countryCode
			);
			if(country)
			this.user.country = country.name;
			
			console.log(this.user);
			return this.user;
		},
		userPicturePath() {
			if (this.profilePicturePath) {
				return this.profilePicturePath;
			}
			return this.userProfileData.profilePicture;
		},
	},
	methods: {
		...mapActions("user", ["fetchCurrentUser", "updateUserProfile"]),
		...mapActions("content", ["uploadContentFile"]),
		toggleModal() {
			this.showModal = !this.showModal;
		},
		togglePasswordModal() {
			this.showChangePasswordModal = !this.showChangePasswordModal;
		},
		updateModalState(value) {
			this.showModal = value;
		},
		updateModalPasswordState(value) {
			this.showChangePasswordModal = value;
		},
		openDialog() {
			$("#image-upload").click();
		},
		getFile(e) {
			const [file] = e.target.files;
			if (file) {
				this.profilePicturePath = URL.createObjectURL(file);
				this.profilePictureFile = file;
				// this.thumbnailMediaType = file.type.split("/")[0];
			}
			console.log("file: ", e.target.files);
		},
		updateProfile() {
			let formData = new FormData();
			formData.append("file", this.profilePictureFile);
			formData.append("entity", "User");
			formData.append("type", "Image");

			console.log(...formData);
			this.uploadContentFile(formData).then((data) => {
				// console.log(data);
				this.profilePicturePath = data;
				this.updateUserProfile({
					id: this.user.id,
					profilePicture: this.profilePicturePath,
				}).then((res) => {
					this.profilePicturePath = null;
					this.profilePictureFile = null;
					this.fetchCurrentUser();
				});
			});
		},
		initBarcodeCode128(ElementID, DataString) {
			JsBarcode(ElementID, DataString, {
				format: "CODE128",
				displayValue: false,
				fontSize: 24,
				lineColor: "black",
			});

			// Png Image.
			var base64 =
				"data:image/svg+xml;base64," +
				btoa(new XMLSerializer().serializeToString($("#barcodeSvg")[0]));
			var img = document.getElementById("barcodeImg");
			img.src = base64;
			this.previewItem = base64;
		},
		handleHide() {
			this.showPreview = false;
		},
		openPreview() {
			this.showPreview = true;
			// }
		},
	},
	created() {
		this.fetchCurrentUser().then((res) => {
			this.initBarcodeCode128("#barcodeSvg", this.user.id);
		});
	},
};
</script>

<style scoped>
@media screen and (max-width: 996px) {
	.profile-container {
		padding: 2rem 1rem !important;
	}
}

.profile-container {
	padding: 2rem;
	max-width: 768px;
}
.profile-input {
	height: unset;
	margin-bottom: 0;
}

label {
	font-size: 0.9rem;
}

.profile-detail-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.profile-picture {
	width: 12.125rem;
	height: 12.125rem;
	object-position: center;
	object-fit: contain;
	background-color: var(--color-white);
	box-shadow: 0 5px 35px #a8a8a829;
	border-radius: 50%;
	margin-top: -6rem;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 5rem;
	line-height: 1;
	cursor: pointer;
}

.profile-picture-edit {
	width: 2.25rem;
	height: 2.25rem;
	background-color: var(--primary-color);
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--color-white);
	border-radius: 50%;
	font-size: 0.875rem;
	position: absolute;
	bottom: 0.75rem;
	right: 0.75rem;
	text-decoration: none;
}

.profile-picture-overlay {
	border-radius: 50%;
}

.profile-picture-container {
	position: relative;
	margin-bottom: 1.75rem;
}

.edit-btn {
	padding: 0.625rem 0.875rem;
	line-height: 1;
	border-color: #d5d5d5;
	border-radius: 6px;
	text-transform: uppercase;
	font-size: 0.875rem;
	color: #000000;
}

.edit-btn-icon {
	margin-right: 0.5rem;
}

.user-name-title {
	font-size: 1.875rem;
	font-weight: 500;
	margin-top: 2.25rem;
	position: relative;
}

.user-name-title::before {
	content: "";
	width: 2rem;
	height: 0.25rem;
	background-color: var(--primary-color);
	position: absolute;
	top: -0.875rem;
	left: calc(50% - 1rem);
}

.user-role {
	color: var(--secondary-color);
	font-size: 1.25rem;
	font-weight: normal;
}

.user-location {
	color: #959595;
	font-weight: normal;
	font-size: 1rem;
}

.user-email {
	margin-bottom: 1rem;
	line-height: 1;
}

.user-address {
	margin-bottom: 1rem;
	line-height: 1;
	text-align: center;
}
</style>
