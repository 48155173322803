<template>
	<div>
		<div
			class="product-details-card hide-tab-mobile"
			v-if="!loading && productVariantData != null"
		>
			<div class="product-image hide-tab-mobile">
				<img
          :src="thumbnailPath"
					:alt="productVariantData.product.friendlyTitle"
					:class="`thumbnail-img ${isThumbnailError ? 'error' : ''}`"
					@error="
						() => {
							isThumbnailError = true;
						}
					"
				/>
          <i v-if="videoPreviewUrl" class="fa fa-play-circle video-thumbnail-icon" @click="openThumbnailPreview"></i>
			</div>
			<div class="product-card-content">
				<p
					class="m-0 text-muted"
					v-if="lowestVariantPrice.discountedPrice > 0 && !hasOnlyOnePlan"
				>
					Starting from<br />
				</p>
				<h3
					v-if="productVariantData.status == ProductVariantStatus.COMING_SOON"
				>
					{{ productVariantData.status }}
				</h3>
				<div v-else-if="!contactUsFormLink">
          <h3
              v-if="plansLoaded"
              :class="`product-price text-bold d-flex justify-content-center gap-2 align-items-center ${
						lowestVariantPrice.discountedPrice == -1 ? 'unavailable' : ''
					}`"
          >
            <span v-if="priceBeforeDiscount != priceAfterDiscount" class="text-danger pre-discounted-price">
              <del>
                {{ parseCurrency(lowestVariantPrice.price, selectedPlan.currency) }}
                <p
                    class="m-0 text-muted superscript"
                    v-if="
                    filteredPricingPlans &&
                    filteredPricingPlans.length > 0 &&
                    filteredPricingPlans[0].type ==
                      PricingPlanType.MULTIPLE_PAYMENT_CYCLES &&
                    hasOnlyOnePlan">
                    /month
                </p>
              </del>
            </span>
            {{
              lowestVariantPrice.discountedPrice > 0
                  ? parseCurrency(lowestVariantPrice.discountedPrice, selectedPlan.currency, 2)
                  : lowestVariantPrice.discountedPrice == 0
                      ? "Free"
                      : "This course is not available in your country"
            }}
            <p
                class="m-0 text-muted superscript"
                v-if="
							filteredPricingPlans &&
							filteredPricingPlans.length > 0 &&
							filteredPricingPlans[0].type ==
								PricingPlanType.MULTIPLE_PAYMENT_CYCLES &&
							hasOnlyOnePlan
						"
            >
              /month
            </p>
            <sub class="discount-percentage-off" v-if="priceBeforeDiscount != priceAfterDiscount">({{discountPercentage}}% off)</sub>
          </h3>


          <div v-else class="d-flex justify-content-center">
            <b-skeleton
                class="hide-tab-mobile mb-2"
                type="button"
                width="50%"
                height="2.5rem"
            ></b-skeleton>
          </div>
        </div>
				<p v-if="productVariantDates">
					<i class="fas fa-history icon-gray"></i>
					{{ productVariantDates }}<br />
				</p>
				<div
					v-if="productVariantData.status != ProductVariantStatus.COMING_SOON"
					class="button-container"
				>
          <div v-if="contactUsFormLink">
            <a class="success-btn" :href="contactUsFormLink" target="_blank">Contact Us</a>
          </div>
					<div v-else>
            <button
                v-if="!isInCart"
                @click="handleClick"
                role="button"
                class="border-btn"
                :disabled="lowestVariantPrice.discountedPrice == -1"
            >
              Add to Cart
            </button>
            <button
                @click="handleClick"
                role="button"
                :class="['success-btn', { cart: isInCart }]"
                :disabled="lowestVariantPrice.discountedPrice == -1"
            >
              {{ btnText }}
            </button>
          </div>
				</div>
				<div
					v-if="productVariantData.features"
					class="product-features-container"
				>
					<div class="or-divider">
						<span>THIS COURSE INCLUDES</span>
					</div>
					<ul class="product-features-list p-0 m-0">
						<li
							class="product-features-list-item"
							v-for="(feature, index) in productVariantData.features"
							:key="index"
						>
							<i class="fas fa-bars icon-gray"></i> {{ feature }}
						</li>
					</ul>
				</div>
			</div>
		</div>

		<div v-else class="product-details-card skeleton">
			<div class="product-image hide-tab-mobile">
				<b-skeleton-img></b-skeleton-img>
			</div>

			<div class="product-card-content d-flex align-items-center">
				<b-skeleton
					class="hide-tab-mobile"
					type="button"
					width="50%"
					height="3rem"
				></b-skeleton>
				<b-skeleton class="hide-tab-mobile mt-3" width="40%"></b-skeleton>

				<div class="button-container width-100 hide-tab-mobile">
					<b-skeleton
						type="button"
						width="95%"
						height="4.5rem"
						class="mt-3"
					></b-skeleton>
					<b-skeleton
						type="button"
						width="95%"
						height="4.5rem"
						class="m-3 mr-0"
					></b-skeleton>
				</div>
				<b-skeleton width="100%" class="mt-3"></b-skeleton>
				<b-skeleton width="100%" class="mt-3"></b-skeleton>
				<b-skeleton width="100%" class="mt-3"></b-skeleton>
				<b-skeleton width="100%" class="mt-3"></b-skeleton>
			</div>
		</div>
    <b-modal
        :visible="showVideoPreview"
        centered
        hide-footer
        hide-header
        @change="closeVideoPreviewModal()"
        size="xl"
        body-class="video-modal-body"
        modal-class="video-modal"
    >
      <div class="body-container">

        <video :src="videoPreviewUrl" class="video-preview" controls autoplay></video>
      </div>
    </b-modal>
	</div>
</template>

<script>
import router from "../../router";
import { mapActions, mapState } from "vuex";
import {
	toCurrencyString,
	toShortDateString,
} from "../../helpers/FormatHelpers";

import {
	PricingPlanType,
	ProductVariantPricingStatus,
} from "../../constants/pricing";

import { ProductVariantStatus } from "../../constants/productVariant";

export default {
	name: "ProductDetailsCard",
	data() {
		return {
			showPromoInput: false,
			promoValue: "",
			activeTab: "0",
			plansLoaded: false,
			selectedPlan: null,
			isThumbnailError: false,
			PricingPlanType,
			ProductVariantPricingStatus,
			ProductVariantStatus,
      showVideoPreview: false
		};
	},
	props: { productVariantData: Object },
	methods: {
		...mapActions("cart", ["addToCart"]),
		...mapActions("pricing", ["fetchPricingPlanByProductVariantId"]),
		togglePromoInput() {
			this.showPromoInput = !this.showPromoInput;
			this.promoValue = "";
		},
		handleClick() {
			if (!this.isInCart) {
				// console.log("buy now clicked");
				if (this.productVariantData.pricingStatus.toLowerCase() == "paid") {
					if (
						this.hasOnlyOnePlan &&
						this.filteredPricingPlans[0].type == PricingPlanType.ONE_TIME
					) {

						var isCartItemsCurrenyIsSame=this.cart.items.find(val=>val.pricing.currency == this.selectedPlan.currency)!=null;

						if (this.cart.items.length > 0 && !isCartItemsCurrenyIsSame) {
							this.$toasted.error(
								"You can only add courses of one currency in your cart.",
								{
									icon: "exclamation-triangle",
									theme: "outline",
									position: "bottom-right",
									duration: 2000,
								});
							return;
						}
						
						this.productVariantData.selectedPlan = this.selectedPlan;
						this.productVariantData.pricing.amount = this.lowestVariantPrice.discountedPrice;
						this.productVariantData.pricing.currency =
							this.selectedPlan.currency;
						this.addToCart(this.productVariantData);
					} else {
						this.$emit("togglePlansModal", true);
					}
				} else {
					this.addToCart(this.productVariantData);
					router.push("/shop/checkout");
				}
			} else {
				router.push("/shop/checkout");
			}
		},
		parseCurrency(amount, currency, decimal) {
			return toCurrencyString(amount, currency, decimal);
		},
    openThumbnailPreview() {
      this.showVideoPreview = true;
    },
    closeVideoPreviewModal() {
      this.showVideoPreview = false;
    }
	},
	computed: {
		...mapState("cart", ["cart"]),
		...mapState("productVariant", ["loading"]),
		...mapState("pricing", ["pricingPlanByProductVariantId"]),
		...mapState("appState", ["currentCurrency", "currentCountry", "systemParameters"]),
		isInCart() {
			var found = false;
			this.cart.items.map((item) => {
				if (item.id == this.productVariantData.id) found = true;
			});
			return found;
		},
		btnText() {
			if (this.isInCart) {
				return "View Cart";
			}
			if (this.lowestVariantPrice.discountedPrice == 0) {
				return "Enroll Now";
			}
			return "Buy Now";
		},
		thumbnailPath() {
			if (this.isThumbnailError) {
				console.log("thumbnail error");
				return require(`@/assets/images/image-error.png`);
			} else {
				if (this.productVariantData.thumbnailPath) {
          var path = !this.productVariantData.thumbnailPath.includes("http")
            ? require(`@/${this.productVariantData.product.thumbnailPath}`)
            : this.productVariantData.thumbnailPath;
          return path;
				} else
					return require(`@/${this.productVariantData.product.thumbnailPath}`);
			}
		},
    videoPreviewUrl() {
      return this.productVariantData.properties.videoPreviewUrl;
    },
		productVariantDates() {
			var dateString = null;
			if (
				this.productVariantData.startDate &&
				!this.productVariantData.endDate
			) {
				dateString =
					"Starting from " +
					toShortDateString(this.productVariantData.startDate);
			} else if (
				!this.productVariantData.startDate &&
				this.productVariantData.endDate
			) {
				dateString =
					"Till " + toShortDateString(this.productVariantData.endDate);
			} else if (
				this.productVariantData.startDate &&
				this.productVariantData.endDate
			) {
				dateString =
					toShortDateString(this.productVariantData.startDate) +
					" - " +
					toShortDateString(this.productVariantData.endDate);
			}
			return dateString;
		},
		filteredPricingPlans() {
			if (
				this.productVariantData &&
				this.pricingPlanByProductVariantId[this.productVariantData.id]
			) {
				return this.pricingPlanByProductVariantId[
					this.productVariantData.id
				].filter(
					(plan) =>
						this.currentCountry &&
						plan.country &&
						plan.country.indexOf(this.currentCountry.toUpperCase()) != -1
				);
			} else {
				return [];
			}
		},
		lowestVariantPrice() {

      let retVal = {
        price: undefined,
        discountedPrice: undefined
      }

	  if(this.productVariantData && this.productVariantData.pricingStatus == ProductVariantPricingStatus.FREE) {
        retVal.price = 0;
        retVal.discountedPrice = 0;
        return retVal;
      }

      if(!this.productVariantData || !this.filteredPricingPlans || this.filteredPricingPlans.length <= 0) {
        retVal.price = -1;
        retVal.discountedPrice = -1;
        return retVal;
      }

      retVal.price = Number.MAX_VALUE;

      this.filteredPricingPlans.forEach((plan) => {
        plan.details.forEach((detail) => {
          let planAmount = detail.amountDetail.find(x => x.title.toLowerCase() == "price").amount || detail.amount;
          let planHasDiscount = detail.amountDetail.find(x => x.title.toLowerCase() == "discount") ? true : false;
          let planDiscount = planHasDiscount ? Math.abs(detail.amountDetail.find(x => x.title.toLowerCase() == "discount").amount) : 0.00;

          let discountedAmount = planAmount - planDiscount;

          if (discountedAmount < (retVal.discountedPrice || retVal.price)) {
            retVal.price = planAmount;
            retVal.discountedPrice = discountedAmount;
          }
        });
      });
      return retVal;

		},
		hasOnlyOnePlan() {
			if (
				this.productVariantData &&
				this.filteredPricingPlans &&
				this.filteredPricingPlans.length > 0
			) {
				return this.filteredPricingPlans.length == 1;
			} else return false;
		},
    contactUsFormLink() {
      return this.productVariantData.properties && this.productVariantData.properties.contact_us_link_for_enrollment ?
          this.productVariantData.properties.contact_us_link_for_enrollment :
          this.systemParameters && this.systemParameters.ContactUsLinkForProductVariantEnrollment ?
              this.systemParameters.ContactUsLinkForProductVariantEnrollment :
              undefined;
    },
    priceBeforeDiscount() {
      return this.lowestVariantPrice.price;
    },
    priceAfterDiscount() {
      return this.lowestVariantPrice.discountedPrice;
    },
    discountPercentage() {
      if(this.priceBeforeDiscount == this.priceAfterDiscount)
        return 0.00;

      return ((1 - (this.priceAfterDiscount/ this.priceBeforeDiscount)) * 100);
    }
	},
	created() {
		if (
			!this.productVariantData ||
			(this.productVariantData &&
				!this.pricingPlanByProductVariantId[this.productVariantData.id])
		) {
			this.fetchPricingPlanByProductVariantId(this.productVariantData.id).then(
				() => {
					this.plansLoaded = true;
					if (this.filteredPricingPlans && this.filteredPricingPlans.length)
						this.selectedPlan = this.filteredPricingPlans[0];
				}
			);
		} else {
			this.plansLoaded = true;
			if (this.filteredPricingPlans && this.filteredPricingPlans.length)
				this.selectedPlan = this.filteredPricingPlans[0];
		}
	},
	watch: {
		productVariantData: function () {
			this.isThumbnailError = false;
			this.plansLoaded = false;
			if (
				!this.productVariantData ||
				(this.productVariantData &&
					!this.pricingPlanByProductVariantId[this.productVariantData.id])
			) {
				this.fetchPricingPlanByProductVariantId(
					this.productVariantData.id
				).then(() => {
					this.plansLoaded = true;
					if (
						this.pricingPlanByProductVariantId[this.productVariantData.id] &&
						this.pricingPlanByProductVariantId[this.productVariantData.id]
							.length > 0
					)
						this.selectedPlan = this.filteredPricingPlans[0];
				});
			} else {
				this.plansLoaded = true;
				if (
					this.pricingPlanByProductVariantId[this.productVariantData.id] &&
					this.pricingPlanByProductVariantId[this.productVariantData.id]
						.length > 0
				)
					this.selectedPlan = this.filteredPricingPlans[0];
			}
		},
	},
};
</script>

<style scoped>
@media screen and (max-width: 996px) {
	.product-details-card {
		top: 1rem !important;
		min-height: unset !important;
		border-radius: 0 !important;
		border: none !important;
		/* width: 100vw !important; */
	}

	.product-details-card-container {
		padding: 0 !important;
		margin: 0 -12px 0 !important;
	}

	.product-card-content {
		padding-bottom: 0 !important;
	}
}

@media screen and (max-width: 768px) {
	.product-card-content {
		font-size: 1.1rem !important;
		padding: 1rem !important;
	}

	.product-card-content p {
		font-size: 1rem !important;
		margin-bottom: 0 !important;
		padding: 1rem !important;
	}

	.add-promo {
		margin-top: 1rem !important;
	}
}

@media screen and (max-width: 600px) {
	.product-details-card {
		top: 0 !important;
	}
}

.product-details-card-container {
	padding-left: 1rem;
}

.product-details-card {
	width: 100%;
	position: relative;
	background-color: var(--color-white);
	top: -10rem;
	border-radius: 0.625rem;
	z-index: 900;
	border: 1px solid #bbbdbf;
	box-shadow: 0 3px 25px #d5d5d529;
}

.product-image {
	display: flex;
	justify-content: center;
	margin: -2rem auto 0px;
	width: 100%;
  align-items: center;
}

.product-image .b-aspect {
	width: 100%;
}

.product-image .b-skeleton-img {
	width: 90%;
	border-radius: 1rem;
	margin: 0 auto;
	background-color: #dddddd;
}

.product-image img {
	height: auto;
	min-height: 17.5rem;
	object-fit: cover;
	object-position: top;
	width: 90%;
	border-radius: 0.75rem;
	box-shadow: 0 -6px 8px #d5d5d529;
	background-color: #efefef;
}
.product-image .thumbnail-img.error {
	object-fit: contain !important;
	padding: 0 8rem;
	object-position: center;
}

.product-image .thumbnail-video {
  cursor: pointer;
}

.product-card-content {
	display: flex;
	flex-direction: column;
	padding: 1.625rem;
	text-align: center;
}

.product-price {
	font-size: 2.125rem;
	font-weight: bold;
}

.product-price.unavailable {
	font-size: 1.5rem;
}

.product-price .pre-discounted-price {
  font-size: 1.25rem;
  font-weight: normal;
}

.discount-percentage-off {
  font-size: 1rem;
  font-weight: normal;
}

.product-card-content p {
	font-size: 0.875rem;
}

.icon-gray {
	color: #a7a9ac;
	margin-right: 0.25rem;
	font-size: 0.875rem;
}

.button-container > div {
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: 1fr;
	margin: 0.875rem 0;
}

.button-container button, .button-container a {
	padding: 1.25rem 0;
	font-size: 1.375rem;
	font-weight: 500;
	line-height: 1.3;
	box-shadow: 0 3px 6px #d5d5d529;
}

.button-container .border-btn {
	margin-right: 0.75rem;
	color: #000000;
}
.button-container .success-btn {
	margin-left: 0.75rem;
}

.button-container .success-btn.cart {
	margin-left: 0;
}

.or-divider {
	font-size: 1.125rem;
	font-weight: bold;
	border-color: #a7a9ac;
}

.or-divider span {
	padding: 0 2rem;
}
.product-features-container {
	margin: 1.25rem 0 0;
}

.product-features-list-item {
	list-style: none;
	font-size: 1.125rem;
	line-height: 2.813rem;
}

.promo-code-container {
	padding: 1.875rem 1.5rem;
	text-align: center;
	border-radius: 0 0 0.625rem 0.625rem;
	background-color: #fafafa;
	border-top: 1px solid #bbbdbf;
}

.add-promo .add-promo-text {
	text-decoration: underline !important;
	font-weight: 500 !important;
	font-size: 1.375rem;
	color: var(--color-black);
}

.promo-delete :hover,
.promo-delete:active,
.promo-delete:visited,
.promo-delete:focus {
	color: var(--color-danger);
}

.promo-delete {
	margin: 0.675rem 2.75rem 0 0 !important;
	color: var(--color-danger);
}

.video-modal-body {
  padding: 0 !important;
}

.video-modal .body-container {
  background: black;
}

.video-thumbnail-icon {
  opacity: 0.5;
  position: absolute;
  font-size: 5rem;
  cursor: pointer;
}

.video-thumbnail-icon:hover {
  opacity: 1;
}

.video-preview {
  width: 100%;
  height: 100%;
}
</style>
