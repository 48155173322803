
<template>
	<div class="user-modal-tab-body devices">
		<div v-if="mode == 3" class="set-limits-container">
			<div class="form-group">
				<label class="main-label" for="status">
					Set user's device limits</label>
				<div class="status-cards">
					<div class="status-card-container">
						<input
							type="radio"
							name="status"
							id="any"
							value="any"
							v-model="platformType"
						/>
						<label class="status-card" for="any">
							<p>Allowed Any Platform</p>
							User will be allowed to login from any platform.
						</label>
					</div>
					
					<div class="status-card-container">
						<input
							type="radio"
							name="status"
							id="specific"
							value="specific"
							v-model="platformType"
						/>
						<label class="status-card" for="specific">
							<p>Allowed Specific Platforms</p>
							User will be allowed to login from only specific platforms.
						</label>
					</div>
				</div>
			</div>
			<h5 class="text-black mt-4">
				Set user's device limits for {{ platformType=="any"?"any platform":"each platforms" }}
			</h5>
			<div class="mt-4 row">
				
				<div
				v-for="platform in platforms"
					class="form-group col-md-3"
					v-if="(platform.item=='any_platform' && platformType=='any') || (platformType=='specific' && platform.item!=='any_platform')"
				>
					<label :for="platform.item" class="main-label">{{platform.name}}</label>
					<select
						type="number"
						:name="platform.item"
						:id="platform.item"
						class="form-select"
						v-model="platformLimits[platform.item]"
						>
						<option 
							v-for="i in [0,1,2,3,4,5,6,7,8,9,10]" 
							:value="isSystemDefaultLimit(platform.item,i)?'default':i" 
							:selected="isSystemDefaultLimit(platform.item,i)"
							> <span v-if="isSystemDefaultLimit(platform.item,i)" >(System Default)</span>
							{{ i }} 
						</option>
					</select>
					
					<span class="right-float-label" v-if="platformLimits[platform.item] && platformLimits[platform.item]>-1" style="margin-right: 45px; bottom: 0.45rem;"> devices </span>
				
				</div>
			</div>

		</div>
		<div  v-if="mode != 3">
			<label for="user-devices" class="main-label">User Devices</label>
			<table class="table table-hover table-borderless enrolled-users-table">
				<thead>
					<tr class="d-flex">
						<th class="col-1">ID</th>
						<th class="col">Brand Name</th>
						<th class="col">Model</th>
						<th class="col">Type</th>
						<th class="col">Platform</th>
					</tr>
				</thead>
				<tbody v-if="devices && devices.length > 0">
					<tr
						:class="['d-flex payment-item']"
						v-for="device in devices"
						:key="device.id"
					>
						<td class="col-1">{{ device.id }}</td>
						<td class="col">
							{{ device.brandName ? device.brandName : "Other" }}
						</td>
						<td class="col">{{ device.model ? device.model : "Other" }}</td>
						<td class="col">
							{{ device.deviceName }}
						</td>
						<td class="col">
							{{ devicePlatform(device.platform) }}
						</td>
					</tr>
				</tbody>
				<div
					v-else-if="devices.length == 0 && loading"
					class="d-flex align-items-center justify-content-center gap-2 p-3 font-500"
				>
					<b-spinner small></b-spinner> Loading...
				</div>
				<div v-else class="no-content">No devices found</div>
			</table>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import Swal from "sweetalert2";
export default {
	name: "UserDevices",
	data() {
		return {
			platformType:null,
			devices: [],
			restrictionsList: {},
			platformLimits:{},
      		setPlatforms: [],
			platforms: [
				{ item: "mobile_web", name: "Mobile Browser" },
				{ item: "desktop_web", name: "Desktop Browser" },
				{ item: "windows", name: "Windows Application" },
				{ item: "macos", name: "Mac Os Application" },
				{ item: "android", name: "Android App" },
        { item: "ios", name: "iOS App" },
        { item: "any_platform", name: "Any Platform" },
			],
		};
	},
	props: { user: Object, mode: Number },
	methods: {
		...mapActions("user", ["fetchUserDevices", "fetchUserDeviceRestrictions"]),
		devicePlatform(platform) {
			if (platform.toLowerCase() == "mobile_web") return "Web (Mobile)";
			else if (platform.toLowerCase() == "desktop_web") return "Web (Desktop)";
			else if (platform.toLowerCase() == "ios") return "iOS App (iPhone/iPad)";
			else if (platform.toLowerCase() == "android")
				return "Android App (Smartphone/Tablet)";
			else if (platform.toLowerCase() == "windows") return "Windows App";
			else if (platform.toLowerCase() == "macos") return "Mac Os App";
			else return "Other";
		},
		setDevices(devices) {
			devices.forEach((device) =>
				this[device] == null
					? (this[device] = 1)
					: (this[device] = this[device])
			);
		},
		showPlatformDropdown(e) {
			e.stopPropagation();
		},
		showInput(platform) {
			var found = this.setPlatforms.find(
				(p) => p.toLowerCase() == platform.toLowerCase()
			);
			if (!found) this[platform] = null;
			return found ? true : false;
		},
		emitDeviceDetails() {

			var platformRestrictions = Object.keys(this.platformLimits)
			.filter((platform) => this.platformLimits[platform]!='default' )
			.map((platform) => {
				return {
					platform: platform
						.split("_")
						.map((p) => p[0].toUpperCase() + p.substring(1))
						.join("_"),
					limit: parseInt(this.platformLimits[platform.toLowerCase()]),
				};
			});
			this.$emit("updateData", platformRestrictions);
		},
		isSystemDefaultLimit(platform,limit){
			return this.systemDefaultDeviceLimits[platform]==(limit+'');
		},
		
		checkState(){
			if (this.userDeviceRestrictions[this.user.id].find(x => x.platform.toLowerCase() == "any_platform")) {
				this.platformType = 'any';
			} else if (this.userDeviceRestrictions[this.user.id].length > 0) {
				this.platformType = 'specific';
			} else if (this.systemDefaultDeviceLimits["any_platform"]) {
				this.platformType = 'any';
			} else if (Object.keys(this.systemDefaultDeviceLimits).length > 0) {
				this.platformType = 'specific';
			} else {
				this.platformType = 'any';
			}
		}	
	},
	computed: {
		...mapState("user", ["userDevices", "loading", "userDeviceRestrictions"]),
		...mapState("appState", [
			"systemParameters",
			"currentCountry",
			"currentCity",
		]),
		systemDefaultDeviceLimits(){

			var deviceLimits={};
			if(this.systemParameters.Any_Platform_Limit)
			{
				deviceLimits["any_platform"] = this.systemParameters.Any_Platform_Limit;
			}
			if(this.systemParameters.Desktop_Web_Limit)
			{
				deviceLimits["desktop_web"] = this.systemParameters.Desktop_Web_Limit;
			}
			if(this.systemParameters.Mobile_Web_Limit)
			{
				deviceLimits["mobile_web"] = this.systemParameters.Mobile_Web_Limit;
			}
			if(this.systemParameters.Android_App_Limit)
			{
				deviceLimits["android"] = this.systemParameters.Android_App_Limit;
			}
			if(this.systemParameters.IOS_App_Limit)
			{
				deviceLimits["ios"] = this.systemParameters.IOS_App_Limit;
			}
			
			if(this.systemParameters.Windows_App_Limit)
			{
				deviceLimits["windows"] = this.systemParameters.Windows_App_Limit;
			}

			if(this.systemParameters.MacOs_App_Limit)
			{
				deviceLimits["macos"] = this.systemParameters.MacOs_App_Limit;
			}

			return deviceLimits;
		}

	},
	mounted() {
		// console.log("render");
		if (this.devices && this.devices.length == 0 && !this.loading) {
			// console.log("disable");
			this.$emit("disable");
		}
	},
	created() {
		if (this.userDevices && !this.userDevices[this.user.id])
			this.fetchUserDevices(this.user).then((devicesList) => {
				if (devicesList) this.devices = devicesList;
				else {
					this.devices = [];
					this.$emit("disable");
				}
				if (
					this.userDeviceRestrictions &&
					!this.userDeviceRestrictions[this.user.id]
				)
					this.fetchUserDeviceRestrictions(this.user).then((res) => {
						if (res) {
							res.forEach(
								(restrict) =>
									(this.restrictionsList[restrict.platform.toLowerCase()] = restrict.limit)
							);
						}
					});
				else {
					this.userDeviceRestrictions[this.user.id].forEach(
						(restrict) =>
							(this.restrictionsList[restrict.platform.toLowerCase()] = restrict.limit)
					);
				}
			});
		else {
			this.devices = this.userDevices[this.user.id];
			if (
				this.userDeviceRestrictions &&
				!this.userDeviceRestrictions[this.user.id]
			)
				this.fetchUserDeviceRestrictions(this.user).then((res) => {
					if (res) {
						this.setPlatforms = res.map((p) => p.platform.toLowerCase());
						res.forEach(
							(restrict) =>
								(this.restrictionsList[restrict.platform.toLowerCase()] = restrict.limit)
						);
					}
				});
			else {
				this.userDeviceRestrictions[this.user.id].forEach(
					(restrict) => (this.restrictionsList[restrict.platform.toLowerCase()] = restrict.limit)
				);
			}
		}
	},
	watch: {
		userDevices: function () {
			this.devices = this.userDevices[this.user.id];
			if (this.devices && this.devices.length == 0) this.$emit("disable");
		},
		userDeviceRestrictions: function(){
				
				this.userDeviceRestrictions[this.user.id].forEach(
					(restrict) =>
					(this.restrictionsList[restrict.platform.toLowerCase()] = restrict.limit)
				);
				this.checkState();
		},
		platformType: function() {
			this.platformLimits={};
			this.platforms
			.forEach(platform => {
				if ((this.platformType == 'any' && platform.item == 'any_platform') || (this.platformType == 'specific' && platform.item != 'any_platform')) {
						if (typeof(this.systemDefaultDeviceLimits[platform.item])!='undefined')
							this.platformLimits[platform.item] = 'default';
						if (typeof(this.restrictionsList[platform.item])!='undefined')
							this.platformLimits[platform.item] = this.restrictionsList[platform.item];

						if (typeof(this.platformLimits[platform.item])=="undefined")
							 	this.platformLimits[platform.item] = 1;
				}
			});
			 
		},
		mode(){
			this.checkState();
		}
	},
};
</script>

<style>
.no-content {
	padding: 1rem;
	text-align: center;
	font-weight: 500;
	color: #666;
	font-size: 1.125rem;
}

.set-limits-container {
	display: flex;
	flex-direction: column;
}

.right-float-label {
	position: absolute;
	right: 0;
	/* bottom: 0.5rem; */
	color: #aaa;
}

.platform-item input[type="checkbox"] {
	background: #ffffff;
	border: 1px solid #b2b2b2;
	box-sizing: border-box;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	width: 1.125rem;
	height: 1.125rem;
}

.platform-item label {
	flex-grow: 1;
}
</style>
