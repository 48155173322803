<template>
	<b-modal id="edit-content-modal" @change="$emit('updateModalState', false)"  :dialog-class="'animate-modal-dialog '+(isExpanded?'expanded':'shrink')" :visible="visible" hide-header centered
		content-class="edit-content-modal" size="xl" body-class="modal-body" hide-footer modal-class="custom-modal"
		no-close-on-backdrop>
		<div class="body-container">
			<div class="right-close-panel">
				<button @click="()=>{ isExpanded=!isExpanded; }" class="close-button expanded-btn mr-3" role="button">
							<i v-if="!isExpanded" class="fas fa-expand-alt"></i>
							<i v-if="isExpanded" class="fas fa-compress-alt"></i>
				</button>
				<button @click="closeModal" class="close-button" role="button">
					<img src="@/assets/images/cancel-icon.svg" />
				</button>
			</div>
			<h1 class="edit-content-title">{{ isEditMode?"Edit":"Create"}} Exam</h1>
			<div :class="['info-container']">
				<!-- <div class="import-btn-container">
					<div></div>
					<button @click="importTest" role="button" class="step-btn" v-if="isImport == false">
						Import Test
					</button>
				</div> -->
				<div class="inline-group">
					<div class="col">
						<label class="main-label" for="title">Title (required)</label>
						<input placeholder="Type Exam Title here..." class="form-input" type="search" name="title" id="title"
							v-model="title" required />
					</div>
				</div>

				<div class="form-group" v-if="isImport == true">
					<div class="flex-row mt-5">
						<div class="col-lg-6 ps-0">
							<div class="form-group">
								<label class="main-label" for="paperIds">Paper ID (required)</label>
								<input placeholder="Type Paper ID here..." class="form-input" type="search"
									name="paperIds" id="paperIds" v-model="paperIds" required />
							</div>
						</div>
						<div class="col-lg-6 ps-0">
							<div class="form-group">
								<label class="main-label" for="testId">Test ID (required)</label>
								<input placeholder="Type Test ID here..." class="form-input" type="search" name="testId"
									id="testId" v-model="testId" required />
							</div>
						</div>
					</div>
				</div>
				<div  v-if="isImport == false">
				
						<div class="inline-group">
							<div class="col">
								<label class="main-label" for="numberOfQuestions">Number Of Questions (required)</label>
								<input placeholder="Type Number Of Questions here...  (0 for all questions)" class="form-input" type="number"
									name="numberOfQuestions" id="numberOfQuestions" v-model="testData.numberOfQuestions" required />
							</div>
				
							<div class="col">
								<label class="main-label" for="maxAttempts">Allowed Attempts (REQUIRED)</label>
								<input placeholder="Type Max Attempts here... (0 for unlimited attempts)" class="form-input" type="number" name="maxAttempts"
									id="maxAttempts" v-model="testData.maxAttempts" required />
							</div>
							<div class="col">
								<label class="main-label" for="selectService">Select service (REQUIRED)</label>
								<div>
									<v-select
										:disabled="isEditMode"
										v-model="selectedServiceCode"
										name="v3examsystem"
										id="v3examsystem"
										class="form-input"
										:options="filteredServices"
										:reduce="(option) => option.serviceCode"
										label="serviceName"
										:appendToBody="true"
										:calculate-position="withPopper"
										placeholder="Select Exam Service"
										v-b-tooltip.hover
										:clearable="false"
										style="padding: 6px 0px"
										:title="isEditMode ? 'This can not be changed. You have to create a new exam if you want to change service' : ''"
									>
									</v-select>
 								</div>
							</div>
						</div>
			
						<div class="inline-group">
							<div class="col">
								<label class="main-label mb-4" for="layout">Layout (required)</label>
								<select class="form-input" placeholder="Select layout" name="layout" id="layout"
									v-model="testData.layout" required>
									<option value="F1toF4"> F1 to F4 (ACCA)</option>
									<option value="Start"> F5 to F9 + Professional Level ACCA Exam Format</option>
								</select>
							</div>
							<div class="col">
								<label class="main-label" for="passingPercentage">Passing Percentage</label>
								<div >
								  <input class="form-input" style="display: inline;" type="number" name="passingPercentage" id="passingPercentage" placeholder="Passing Percentage" min="1" max="100" v-model="testData.passingPercentage" />
								</div>							
							</div>
							<div class="col">
								<label class="main-label" for="totalTime">Total Time (required)</label>
								<div >
									<input class="form-input" style="width: 15%; display: inline;" type="number" name="hour" id="hour" placeholder="hh" min="0" max="24" v-model="testData.hour" required /><strong> : </strong> 
                                    <input class="form-input" style="width: 15%; display: inline;" type="number" name="minute" id="minute" placeholder="mm" min="0" max="60" v-model="testData.minute" required /><strong> : </strong>
                                    <input class="form-input" style="width: 15%; display: inline;" type="number" name="second" id="second" placeholder="ss" min="0" max="60" v-model="testData.second" required />
								</div>							
							</div>
							
						</div>
				
						<div class="inline-group">
							<div class="col">
								<div class="form-check form-switch">
									<input class="form-check-input" type="checkbox" role="switch" id="isRandom"
										v-model="testData.isRandom" />
									<label class="form-check-label" for="isRandom">Is Random
									</label>
								</div>
							</div>
							<div class="col">
								<div class="form-check form-switch">
									<input class="form-check-input" type="checkbox" role="switch" id="isReviewAllowed"
										v-model="testData.isReviewAllowed" />
									<label class="form-check-label" for="isReviewAllowed">Review Allowed
									</label>
								</div>					
							</div>
							<div class="col">
								<div class="form-check form-switch">
									<input class="form-check-input" type="checkbox" role="switch" id="isActive"
										v-model="testData.status" />
									<label class="form-check-label" for="isActive">Is Active
									</label>
								</div>			
							</div>
							<div class="col">
								<div class="form-check form-switch">
									<input class="form-check-input" type="checkbox" role="switch" id="sendToReviewOnSubmit"
										v-model="testData.sendToReviewOnSubmit" />
									<label class="form-check-label" for="sendToReviewOnSubmit">Send To Review On Submit
									</label>
								</div>
							</div>
						</div>
			
				</div>

				<div class="inline-group">
					<div class="col">
					
						<label for="start-date" class="main-label m-0">start date (optional)</label>
						<input placeholder="Select start date" class="form-input" type="date" name="availableFrom" id="availableFrom"
							v-model="availableFrom" />
					
					</div>
					<div class="col">
						<label for="end-date" class="main-label m-0">end date (optional)</label>
						<input placeholder="Select end date" class="form-input" type="date" name="availableTo" id="availableTo"
							v-model="availableTo" />
					</div>
				</div>
					<!-- 				
														<div class="form-group mt-4" >
															<div class="form-check form-switch">
																<input class="form-check-input" type="checkbox" role="switch" id="isContentAvailableForDemo"
																	name="isContentAvailableForDemo" v-model="isContentAvailableForDemo" />
																<label class="form-check-label" for="isContentAvailableForDemo">Available for Demo</label>
															</div>
														</div> -->		</div>
			<div class="step-btn-container">
				<button @click="createExam" role="button" class="step-btn" :disabled="disableNext() || loading">
					<b-spinner v-if="loading" small></b-spinner>
					{{ stepButtonContent }}
					<img src="@/assets/images/arrow-right.svg" />
				</button>
			</div>
		</div>
	</b-modal>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { ServiceCodes } from "../../../constants/serviceCodes";
import { createPopper } from "@popperjs/core";
import { ContentType } from "../../../constants/content";

export default {
	name: "CreateEditLegacyExamModal",
	data() {
		return {
			title: null,
			availableFrom: null,
			availableTo: null,
			isContentAvailableForDemo: false,
			updateObject: {},
			paperIds: null,
			testId: null,
			isImport: false,
			testData: {
				layout: null,
				status: true,
				sendToReviewOnSubmit:false,
				passingPercentage:null,
				isRandom: true,
				maxAttempts: null,
				numberOfQuestions: null,
				isReviewAllowed: true,
				hour: null,
				minute: null,
				second: null
			},
			isExpanded: false,
			ServiceCodes,
			selectedServiceCode: null,
			ContentType
		};
	},
	computed: {
		...mapState("appState", ["externalServicesList"]),
		...mapState("exam", ["loading"]),
		...mapState("user", ["user"]),
		visible() {
			return this.showModal;
		},
		stepButtonContent() {
			return this.isEditMode?"Save":"Create";
		},		
		isEditMode() {
			return this.mode == "edit";
		},
		filteredServices() {
			return this.externalServicesList[ContentType.EXAM]?.filter((x) => x.serviceProvider == ServiceCodes.EXAM.V3ExamSystem) ?? [];
		}
	},
	watch: {

		visible: function () {
			if (this.mode == "edit" && this.visible) {
				this.title = this.contentData.title;
				if (this.contentData.availableFrom){
					this.availableFrom =new Date(this.contentData.availableFrom).toISOString().split('T')[0];
				}
				if (this.contentData.availableTo){
					this.availableTo =new Date( this.contentData.availableTo).toISOString().split('T')[0];
				}
				var prop=JSON.parse(this.contentData.extraProperties);
				if (prop.Layout)
					this.testData.layout = prop.Layout;

				if (prop.Status)
					this.testData.status = prop.Status;
					
				if (prop.SendToReviewOnSubmit)
					this.testData.sendToReviewOnSubmit = prop.SendToReviewOnSubmit;

				if (prop.PassingPercentage)
					this.testData.passingPercentage = prop.PassingPercentage;
					
				if (prop.IsRandom)
					this.testData.isRandom = prop.IsRandom;

				if (prop.MaxAttempts)
					this.testData.maxAttempts = prop.MaxAttempts;
                 
				if (prop.NoOfQuestions)
					this.testData.numberOfQuestions = prop.NoOfQuestions;

				if (prop.IsReviewAllowed)
					this.testData.isReviewAllowed = prop.IsReviewAllowed;

				if(prop.TotalTime){

					var hours=prop.TotalTime.split("h");
					var mins=(hours.length>1?hours[1]:prop.TotalTime).split("m");
					var secs=(mins.length>1?mins[1]:prop.TotalTime).split("s");
					this.testData.hour=hours.length>0?hours[0]:0;
					this.testData.minute=mins.length>0?mins[0]:0;
					this.testData.second=secs.length>0?secs[0]:0;
				}

				if(this.contentData?.externalService?.serviceCode) {
					this.selectedServiceCode = this.contentData?.externalService?.serviceCode;
				}
			}
		}
	},
	props: {
		showModal: Boolean,
		contentData: { default: null, type: Object },
		parentContentId: String,
		productVariantId: String,
		mode: String,
	},
	methods: {
		...mapActions("content", [
			"getContentById","updateContent"
		]),
		...mapActions("exam", ["createLegacyExam","updateExam"]),

		withPopper(dropdownList, component, { width }) {
			// console.log(dropdownList, component.$refs.toggle, { width });
			dropdownList.style.width = width;
			const popper = createPopper(component.$refs.toggle, dropdownList);

			return () => popper.destroy();
		},

		closeModal() {
			Object.assign(this.$data, this.$options.data.call(this));
			this.$emit("updateModalState", false);
		},
		disableNext() {
			// console.log(Object.keys(this.updateObject).length);
			if (this.title && this.isImport && this.paperIds && this.testId) {
				return false;
			}
			
			if (this.title && this.isImport==false && this.testData && this.testData.maxAttempts 
				&& this.testData.numberOfQuestions && this.testData.layout && this.testData.hour && this.testData.minute && this.testData.second && this.selectedServiceCode) {
				return false;
			}
			return true;
		},
		importTest() {
			this.isImport = true;
		},
		createExam() {

            var data={};

			if(this.isImport){
				data= {
					paperids: this.paperIds,
					servicecode: this.selectedServiceCode,
					examid: this.testId,
				};
			}else{

				data = {
					servicecode: this.selectedServiceCode,
					istestcreationrequired: "true",
					layout: this.testData.layout,
					status: this.testData.status+"",
					israndom: this.testData.isRandom+"",
					sendtoreviewonsubmit: this.testData.sendToReviewOnSubmit+"",
					passingpercentage: this.testData.passingPercentage,
					maxattempts: this.testData.maxAttempts,
					numberofquestions: this.testData.numberOfQuestions,
					isreviewallowed: this.testData.isReviewAllowed+"",
					totaltime: this.testData.hour.padStart(2, '0')+"h"+this.testData.minute.padStart(2, '0')+"m"+this.testData.second.padStart(2, '0')+"s"
				}              

			}

			var createExamObj = {
				id:this.contentData?.id??null,
				title: this.title,
				data: data,
				availableFrom: this.availableFrom,
				availableTo: this.availableTo,
				isAvailableForDemo: this.isContentAvailableForDemo,
				parentDirectoryID: this.parentContentId,
				type: "exam",
			};
			if (this.isEditMode) {
				this.updateContent(createExamObj).then((res) => {
					if (res) {
						this.getContentById({id:this.contentData.id});
						this.closeModal();
						this.$toasted.success("Exam updated successfully", {
							theme: "outline",
							position: "bottom-center",
							duration: 3000,
						});
						
					}
				});
			} else {
				this.createLegacyExam(createExamObj).then((res) => {
					if (res) {
						this.closeModal();
						this.$toasted.success("Exam created successfully", {
							theme: "outline",
							position: "bottom-center",
							duration: 3000,
						});
					}
				});
			}
		},
	},
};
</script>
<style scoped>
	.right-close-panel
	{
		position: absolute;
    	top: 2.5rem;
    	right: 2rem;
		display: flex;
	}

	.right-close-panel .close-button
	{
		position: initial;
	}
	
	.expanded-btn{
		color: black;
	    font-size: 24px;
	    margin-right: 15px;
		padding-top: 4px;
	}
</style>
<style>
.edit-content-modal {
	min-height: 40rem;
	/* width: 70vw; */
}

.import-btn-container {
	float: right;
}

.import-btn-container .step-btn {
	width: 150px;
}

.edit-content-modal .modal-body {
	display: flex;
	flex-direction: row;
	padding: 0 !important;
	font-size: 1rem;
}

.edit-content-modal .modal-body .body-container {
	padding: 2.5rem;
	display: flex;
	flex-direction: column;
	width: 100%;
	position: relative;
}

.edit-content-modal .edit-content-title {
	font-weight: 450;
	font-size: 2rem;
	line-height: 0.75;
	margin: 0.5rem 0 2rem;
	color: #000000;
}

#edit-content-modal .expanded
{
	max-width: 96%;
	max-height: 96%;

}

#edit-content-modal .expanded .edit-content-modal
{
	height: 90vh;
	
}
.all-trainers-list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.trainer-list-container {
	margin-top: 1.5rem;
}
</style>
